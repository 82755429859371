import React, { Component } from 'react';
import {Link} from "react-router-dom";
import "./inicio.css";
import classNames from "classnames";
import Select from "react-select";
import Grid from "../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import LoadMask from "../../../../../../frontend/src/js/common/components/Utils/LoadMask/LoadMask";


class Inicio extends Component {

    state = {
        datUsers: { count: 0, results: [], },
        datProy: { count: 0, results: [], },
        datGuest: { count: 0, results: [], },
        pageUsers: 1,
        pageProy: 1,
        pageGuest: 1,
        filtro: 6,
        subscriptions: [],
    };

    getData = () => {
        this.props.getUsersBySubs(1, this.state.filtro);
    };
    componentDidMount() {
        this.props.getUsersBySubs(1, this.state.filtro);
        this.props.getGuestBySubs(1);
        this.props.getProBySubs(1);
        this.props.getSubscription();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== nextContext) {

            if (nextProps.item.length > 0) {
                nextProps.item.forEach((val) => {
                    val.key === "NUMBER_OF_PROJECTS" && this.setState({ numberProject: val.value });
                });
            }

            this.setState({
                subscriptions: nextProps.subscription.map(val => {
                    return { label: val.type, value: val.id }
                })
            });
            this.setState({ datProy: nextProps.projects });
            this.setState({ datUsers: nextProps.users });
            this.setState({ datGuest: nextProps.guest });
        }
    }

    render() {
        const {
            props: { projects: { results, count }, loaderP, loaderU, loaderG },
            state: { datUsers, datProy, datGuest, pageUsers, pageProy, pageGuest, filtro, subscriptions }
        } = this;
        return (
            <div className="div-border-round m-4">
                <LoadMask loading={loaderU || loaderP || loaderG} blur positionM="inherit">
                    <div className="form-row align-items-end">
                        <div className={`form-group input-group col-md-12 col-lg-6 text-left`}>
                            <div className={`form-group input-group col-md-12 col-lg-8`}>
                            </div>
                            <div className={`form-group input-group col-md-12 col-lg-4`}>
                            </div>
                        </div>
                        <div className={`form-group input-group col-md-12 col-lg-6 text-right sinbordeSelect`}>
                            <div className="form-row align-items-end" style={{ width: "100%" }}>
                                <div className={`form-group input-group col-md-6`}>
                                    <h5 className="textoAzul text-bold">Select subscription</h5>
                                </div>
                                <div className={`form-group input-group col-md-6`}>
                                    <Select
                                        className={classNames('react-select-containerS text-left')}
                                        classNamePrefix={classNames('react-select-containerS')}
                                        menuPlacement="auto"
                                        placeholder="Order by"
                                        backspaceRemovesValue={false}
                                        value={_.find(subscriptions, { value: filtro })}
                                        isMulti={false}
                                        options={subscriptions}
                                        onChange={(e) => this.setState({ filtro: e.value }, () => this.getData())}
                                        styles={
                                            {
                                                control: styles => ({ ...styles, width: "100%", borderRadius: 0, borderColor: 'white', backgroundColor: 'white' }),
                                                menu: styles => ({ ...styles, borderRadius: 0, backgroundColor: 'white' }),
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <h4>USERS BY SUBSCRIPTION</h4>
                    <Grid
                        page={pageUsers}
                        hover
                        data={datUsers}
                        // loading={loaderU}
                        sortLocal
                        onPageChange={(e) => {this.props.getUsersBySubs(e, filtro); this.setState( { pageUsers: Number(e) }) } }
                    >
                        <TableHeaderColumn isKey tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}
                            dataAlign="left" dataField="first_name" dataSort>First name</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}
                            dataAlign="left" dataField="last_name" dataSort>Last name</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}
                            dataAlign="left" dataField="username" dataSort>Email</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}
                            dataAlign="left" dataField="state" dataSort>State</TableHeaderColumn>
                    </Grid>
                    <h4>PROJECTS BY SUBSCRIPTION</h4>
                    <Grid
                        page={pageProy}
                        hover
                        data={datProy}
                        // loading={loaderP}
                        sortLocal
                        onPageChange={(e) => {this.props.getProBySubs(e); this.setState( { pageProy: Number(e) }) } }
                    >
                        <TableHeaderColumn isKey tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}
                            dataAlign="left" dataField="type" dataSort>Subscription type</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}
                            dataAlign="left" dataField="open" dataSort>Open projects</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}
                            dataAlign="left" dataField="finish" dataSort>Finished projects</TableHeaderColumn>
                    </Grid>
                    <h4>GUEST USERS BY SUBSCRIPTION</h4>
                    <Grid
                        page={pageGuest}
                        hover
                        data={datGuest}
                        // loading={loaderG}
                        sortLocal
                        onPageChange={(e) => {this.props.getGuestBySubs(e); this.setState( { pageGuest: Number(e) }) } }
                    >
                        <TableHeaderColumn isKey tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}
                            dataAlign="left" dataField="type" dataSort>Subscription type	</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}
                            dataAlign="left" dataField="guest" dataSort>Guest users</TableHeaderColumn>
                    </Grid>
                </LoadMask>
            </div>
        );
    }
}

export default Inicio;
