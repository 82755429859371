import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';

class LoginForm extends Component {
    state = {
        tipoInput: "password",
    };

    render() {
        const { props:{ handleSubmit, pristine, reset, submitting, errorLogin }, state: { tipoInput } } = this;
        return (
            <form name="loginForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
                <div className="form-group has-feedback">
                    <Field
                        name="username"
                        label="Username"
                        component={renderField}
                        type="text"
                        className="form-control"
                        placeholder="Username"
                    />
                </div>
                <div className="form-group has-feedback">
                    <div className="inputPass">
                        {tipoInput === "password" && (
                            <i
                                style={{ cursor: "pointer" }}
                                className="fa fa-eye fa-lg"
                                aria-hidden="true"
                                onClick={() => this.setState({ tipoInput: "text" })}
                            />)
                        }
                        {tipoInput === "text" && (
                            <i
                                style={{ cursor: "pointer" }}
                                className="fa fa-eye-slash fa-lg"
                                aria-hidden="true"
                                onClick={() => this.setState({ tipoInput: "password" })}
                            />)
                        }
                        <Field
                            name="password"
                            label="Contraseña"
                            component={renderField}
                            type={tipoInput}
                            className="form-control"
                            placeholder="Password"
                        />
                    </div>

                </div>
                <div className="buttons-box">
                    <button type="submit" className="btn btn-primary m-1 align-self-center bt-Admin">Login</button>
                    {
                        errorLogin !== '' && (
                            <div className="invalid-feedback d-block">
                                { errorLogin }
                            </div>
                        )
                    }
                </div>
            </form>
        );
    }
}

export default reduxForm({
    form: 'login', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            username: validators.exists()('This field is required'),
            password: validators.exists()('This field is required'),
        });
    },
})(LoginForm);
