import { handleActions } from 'redux-actions';
import { initialize as initializeForm } from 'redux-form';
import { AlertManager } from "alerts";
import { api } from "api";
import {push} from "react-router-redux";
import Swal from "sweetalert2";
import {setsubscription} from "../../../../../../frontend/src/js/redux/modules/cuenta/profile";

const LOADERP = 'INICIO_LOADERP';
const LOADERU = 'INICIO_LOADERU';
const LOADERG = 'INICIO_LOADERG';
const PROJECTS = 'INICIO_PROJECTS';
const USERS = 'INICIO_USERS';
const GUEST = 'INICIO_GUEST';
const SUBSCRIPTION = 'INICIO_SUBSCRIPTION';

export const constants = {
    LOADERP,
    LOADERU,
    LOADERG,
    PROJECTS,
    USERS,
    GUEST,
    SUBSCRIPTION,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setProjects = projects => ({
    type: PROJECTS,
    projects,
});

export const setUsers = users => ({
    type: USERS,
    users,
});

export const setGuest = guest => ({
    type: GUEST,
    guest,
});

const setLoaderP = loaderP => ({
    type: LOADERP,
    loaderP,
});
const setLoaderU = loaderU => ({
    type: LOADERU,
    loaderU,
});
const setLoaderG = loaderG => ({
    type: LOADERG,
    loaderG,
});
const setSubscription = subscription => ({
    type: SUBSCRIPTION,
    subscription,
});
// ------------------------------------
// Actions
// ------------------------------------

export const getUsersBySubs = (page, filtro) => (dispatch) => {
    dispatch(setLoaderU(true));
    const url = filtro ? `dashboard/users_by_subs/?filter=${filtro}&page=${page}`
        : `dashboard/users_by_subs/?page=${page}`;
    api.get(url).then((data) => {
        dispatch(setUsers(data));
    }).catch(() => {}).finally(() => dispatch(setLoaderU(false)));
};

export const getGuestBySubs = page => (dispatch) => {
    dispatch(setLoaderG(true));
    api.get(`dashboard/guest_by_sub/?page=${page}`).then((data) => {
        dispatch(setGuest(data));
    }).catch(() => {}).finally(() => dispatch(setLoaderG(false)));
};

export const getProBySubs = page => (dispatch) => {
    dispatch(setLoaderP(true));
    api.get(`dashboard/proy_by_sub/?page=${page}`).then((data) => {
        dispatch(setProjects(data));
    }).catch(() => {}).finally(() => dispatch(setLoaderP(false)));
};

export const getSubscription = () => (dispatch) => {
    api.get('subscriptions/all/').then((dataSub) => {
        dispatch(setSubscription(dataSub));
    }).catch(() => {}).finally(() => {});
}

export const actions = {
    getUsersBySubs,
    getGuestBySubs,
    getProBySubs,
    getSubscription,
};

export const reducers = {
    [LOADERP]: (state, { loaderP }) => {
        return {
            ...state,
            loaderP,
        };
    },
    [LOADERU]: (state, { loaderU }) => {
        return {
            ...state,
            loaderU,
        };
    },
    [LOADERG]: (state, { loaderG }) => {
        return {
            ...state,
            loaderG,
        };
    },
    [PROJECTS]: (state, { projects }) => {
        return {
            ...state,
            projects,
        };
    },
    [USERS]: (state, { users }) => {
        return {
            ...state,
            users,
        };
    },
    [GUEST]: (state, { guest }) => {
        return {
            ...state,
            guest,
        };
    },
    [SUBSCRIPTION]: (state, { subscription }) => {
        return {
            ...state,
            subscription,
        };
    },
};

export const initialState = {
    loaderP: false,
    loaderU: false,
    loaderG: false,
    projects: { count: 0, results: [] },
    users: { count: 0, results: [] },
    guest: { count: 0, results: [] },
    subscription: [],
};

export default handleActions(reducers, initialState);
