import React from 'react';


export const Policy = () => {
    return (
        <div style={{
            "height": "100%",
            "margin-top": "10px",
            "margin-left": "5px",
            "padding": "20px",
        }}>
            <h1 style={{"text-align:": "center", "outline": "0px"}}
                                           tabIndex="-1">Privacy Policy</h1><br /><p
            id="ngdialog2-aria-describedby">Effective: April 1, 2017</p><h2>Introduction</h2><p align="justify">This
            privacy policy explains how Onepage handles your personal information and data. We value your trust, so we
            have strived to present this policy in clear, plain language instead of legalese. The policy is structured
            so you can quickly find answers to the questions that interest you the most.</p><p align="justify">This
            privacy policy applies to all the products, services and websites offered by Onepage.business Inc., and
            their affiliates, except where otherwise noted. We refer to those products, services and websites
            collectively as the “services” or “the site” and to each person or entity (each a “User“) in this
            policy.</p><p align="justify">Please read this document carefully. This document governs your relationship
            with Onepage.business Inc. (“Onepage“). Set forth below are the terms and conditions under which Onepage
            makes available its internet websites, including www.Onepage.business (the “Site“), and the services
            available through the Site or otherwise provided by Onepage (the “Services“), to each person or entity (each
            a “User“) accessing or using the Site or the Services.</p><p align="justify">What information does Onepage
            collect?</p><p align="justify">When you use Onepage, we collect information relating to you and your use of
            our services from a variety of sources. These are listed below. The sections afterward describe what we do
            with this information.</p><h2>Information we collect directly from you</h2>
            <ol type="a" align="justify">
                <li>Registration information. You need an Onepage account before you can create projects on Onepage.
                    When you register for an account, we collect your username, password and email address. If you
                    choose to register by using a third party account (such as your Google or Facebook account), please
                    see “Information from third parties” below.
                </li>
                <br />
                    <li>Billing information. If you make a payment to Onepage, we require you to provide your billing
                        details, such as a name, address, email address and financial information corresponding to your
                        selected method of payment (e.g. a credit card number and expiration date or a bank account
                        number). If you provide a billing address, we will regard that as the location of the account
                        holder.
                    </li>
                    <br />
                        <li>Account settings. You can set various preferences and personal details on pages like your
                            account settings page. For example, your default language, time zone and communication
                            preferences (e.g. opting in or out of receiving marketing emails from Onepage).
                        </li>
                        <br />
                            <li>Address book information. We may allow you to import email addresses into an Address
                                Book and associate email addresses with email invitation collectors so you can easily
                                invite people to review your projects via email. We don’t use these email addresses for
                                our own purposes or email them except at your direction.
                            </li>
                            <br />
                                <li>We get some of it by recording how you interact with our products by, for example,
                                    using technologies like cookies, and receiving error reports or usage data from
                                    software running on your device.
                                </li>
                                <br />
                                    <li>Usage data. We might collect data about how you and your device interact with
                                        Onepage and our products. For example:
                                    </li>
                                    <br />
                                        <ol align="justify">
                                            <li>Product use data. We may collect data usage about you whenever you
                                                interact with our services. This may include which webpages you visit,
                                                what you click on, when you performed those actions, and so on.
                                                Additionally, like most websites today, our web servers keep log files
                                                that record data each time a device accesses those servers. The log
                                                files contain data about the nature of each access, including
                                                originating IP addresses, internet service providers, the files viewed
                                                on our site (e.g., HTML pages, graphics, etc.), operating system
                                                versions, and timestamps.
                                            </li>
                                            <br />
                                                <li>Device data. We may collect data about your device and the network
                                                    you use to connect to our products. It includes data about the
                                                    operating systems and other software installed on your device,
                                                    including product keys. It also includes IP address, device
                                                    identifiers (such as the IMEI number for phones), and regional and
                                                    language settings.
                                                </li>
                                                <br />
                                                    <li>Information from third parties. We may collect your personal
                                                        information or data from third parties if you give permission to
                                                        those third parties to share your information with us. For
                                                        example, you have the option of registering and signing into
                                                        Onepage with your Facebook account details. If you do this,
                                                        Facebook handles the authentication of your logon details and we
                                                        only collect information about your Facebook account that you
                                                        expressly agree to share with us at the time you give permission
                                                        for your Onepage account to be linked to your Facebook account.
                                                    </li>
                                                    <br />
                                                        <li>Information from page tags. We may use third party tracking
                                                            services that employ cookies and page tags (also known as
                                                            web beacons) to collect aggregated and anonymized data about
                                                            visitors to our websites. This data includes usage and user
                                                            statistics. Emails sent by Onepage or by users through our
                                                            services may include page tags that allow the sender to
                                                            collect information about who opened those emails and
                                                            clicked on links in them. We do this to allow the email
                                                            sender to measure the performance of their email messaging
                                                            and to learn how to improve email deliverability and open
                                                            rates.
                                                        </li>
                                                        <br />
                                                            <li>Error reports and performance data. We may collect data
                                                                about the performance of the products and any problems
                                                                you experience with them. This data helps us to diagnose
                                                                problems in the products you use, and to improve our
                                                                products and provide solutions. Depending on your
                                                                product and settings, error reports can include data
                                                                such as the type or severity of the problem, details of
                                                                the software or hardware related to an error, contents
                                                                of files you were using when an error occurred, and data
                                                                about other software on your device.
                                                            </li>
                                                            <br />
                                                                <li>Support Data. When you engage Onepage for support,
                                                                    we may collect data about you and your hardware,
                                                                    software, and other details related to the support
                                                                    incident. Such data includes contact or
                                                                    authentication data, the content of your chats and
                                                                    other communications with Onepage support, data
                                                                    about the condition of the machine and the
                                                                    application when the fault occurred and during
                                                                    diagnostics, and system and registry data about
                                                                    software installations and hardware configurations.
                                                                </li>
                                                                <br /></ol>
                                        <li>Interests and favorites. We may collect data about your interests and
                                            favorites. In addition to those you explicitly provide, your interests and
                                            favorites may also be inferred or derived from other data we collect.
                                        </li>
                                        <br />
                                            <li>Location data. We may collect data about your location, which can be
                                                either from Global Position System (GPS) data, as well as data
                                                identifying nearby cell towers and Wi-Fi hotspots, we collect when you
                                                enable location-based products or features. Imprecise location data
                                                includes, for example, a location derived from your IP address or data
                                                that indicates where you are located with less precision, such as at a
                                                city or postal code level.
                                            </li>
                                            <br />
                                                <li>Content. We may collect content of your files and communications
                                                    when necessary to provide you with the products you use.
                                                </li>
                                                <br />
                                                    <li>Other data you intentionally share. We may collect your personal
                                                        information or data if you submit it to us in other contexts.
                                                        For example, if you provide us with a testimonial, or
                                                        participate in an Onepage contest.
                                                    </li></ol>
            <h2>How We Use Personal Data</h2><p>Onepage uses the data we collect to provide you the products we offer,
                which includes using data to improve and personalize your experiences. We also may use the data to
                communicate with you, for example, informing you about your account, security updates and product
                information. In addition, we use data to help show more relevant ads, whether in our own products, or in
                products offered by third parties. However, we do not use what you say in email, chat, video calls or
                voice mail, or your documents, photos or other personal files to target ads to you.</p><p>Onepage uses
                the data we collect for three basic purposes, described in more detail below: (1) to operate our
                business and provide (including improving and personalizing) the products we offer, (2) to send
                communications, including promotional communications, and (3) for some products, to show
                advertising.</p>
            <ol type="a" align="justify">
                <li>To provide you with our services. This includes providing you with customer support, which requires
                    us to access your information to assist you (such as with project design and creation or technical
                    troubleshooting).
                </li>
                <br />
                    <li>Certain features of our services may use the content of your analysis and your account
                        information in additional ways. Feature descriptions will clearly identify where this is the
                        case. You can avoid the use of your project data in this way by simply choosing not to use such
                        features. For example, by using our Q&amp;A feature, to add questions to your projects, you also
                        permit us to aggregate the responses you receive to those questions with responses received by
                        other Q&amp;A users who have used the same questions. We may then report statistics about the
                        aggregated (and de-identified) data sent to you and other projects creators.
                    </li>
                    <br />
                        <li>If you choose to link your Onepage account to a third party account (such as your Google or
                            Facebook account), we may use the information you allow us to collect from those third
                            parties to provide you with additional features, services, and personalized content.
                        </li>
                        <br />
                            <li>In order to provide you with useful options to use the services together with social
                                media and other applications, we may give you the option to export information to, and
                                collect information from, third party applications and websites. When exporting and
                                collecting such information, you may be disclosing your information to the individuals
                                or organizations responsible for operating and maintaining such third party applications
                                and sites, and your information may be accessible by others visiting or using those
                                applications or sites. We do not own or operate the applications or websites that you
                                connect with, and you should review the privacy policies and statements of such websites
                                to ensure you are comfortable with the ways in which they use the information you share
                                with them.
                            </li>
                            <br />
                                <li>To manage our services. We internally use your information, including certain data,
                                    for the following limited purposes:
                                </li>
                                <br />
                                    <ol align="justify">
                                        <li>To monitor, maintain, and improve our services and features. We internally
                                            perform statistical and other analysis on information we collect (including
                                            usage data, device data, referral data, question and response data and
                                            information from page tags) to analyze and measure user behavior and trends,
                                            to understand how people use our services, and to monitor, troubleshoot and
                                            improve our services, including to help us evaluate or devise new features.
                                            We may use your information for internal purposes designed to keep our
                                            services secure and operational, such as for troubleshooting and testing
                                            purposes, and for service improvement, marketing, research and development
                                            purposes.
                                        </li>
                                        <br />
                                            <li>To enforce our Terms of Use.</li>
                                            <br />
                                                <li>To prevent potentially illegal activities.</li>
                                                <br />
                                                    <li>To screen for and prevent undesirable or abusive activity. For
                                                        example, we have automated systems that screen content for
                                                        phishing activities, spam, and fraud.
                                                    </li>
                                                    <br />
                                                        <li>To create new services, features or content. We may use your
                                                            data and for our internal purposes to create and provide new
                                                            services, features or content. When we do this, none
                                                            individual project creators will be identified or
                                                            identifiable unless we have obtained their permission.
                                                        </li>
                                                        <br />
                                                            <li>To facilitate account creation and the logon process. If
                                                                you choose to link your Onepage account to a third party
                                                                account (such as your Google or Facebook account), we
                                                                use the information you allowed us to collect from those
                                                                third parties to facilitate the account creation and
                                                                login process. For more information, click here.
                                                            </li>
                                                            <br />
                                                                <li>To contact you about your service or account. We
                                                                    occasionally send you communications of a
                                                                    transactional nature (e.g. service-related
                                                                    announcements, billing-related matters, changes to
                                                                    our services or policies, a welcome email when you
                                                                    first register). You cannot opt out of these
                                                                    communications since they are required to provide
                                                                    our services to you.
                                                                </li>
                                                                <br />
                                                                    <li>To contact you for marketing purposes. We will
                                                                        only do this if you have consented to our
                                                                        contacting you for this purpose. For example,
                                                                        during the account registration process we will
                                                                        ask for your permission to use your information
                                                                        to contact you for promotional purposes. You may
                                                                        opt out of these communications at any time by
                                                                        clicking on the “unsubscribe” link in them, or
                                                                        changing the relevant setting on your My Account
                                                                        page.
                                                                    </li>
                                                                    <br />
                                                                        <li>To respond to legal requests and prevent
                                                                            harm. If we receive a subpoena or other
                                                                            legal request, we may need to inspect the
                                                                            data we hold to determine how to respond.
                                                                        </li>
                                                                        <br /></ol></ol>
            <h2>With whom do we share or disclose your information?</h2><p>We recognize that you have entrusted us with
                safeguarding the privacy of your information.</p><p>Because that trust is very important to us, the only
                time we will disclose or share your personal information or data with a third party is when we have done
                one of three things, in accordance with applicable law: (a) given you notice, such as in this privacy
                policy; (b) obtained your express consent, such as through an opt-in checkbox; or (c) de-identified or
                aggregated the information so that individuals or other entities cannot reasonably be identified by it.
                Where required, we will obtain your express consent prior to disclosing or sharing any personal
                information.</p><p>We may disclose:</p>
            <ol type="a" align="justify">
                <li>Your information to our service providers. We use service providers who help us to provide you with
                    our services. We give relevant persons working for some of these providers access to your
                    information, but only to the extent necessary for them to perform their services for us. We also
                    implement reasonable contractual and technical protections to ensure the confidentiality of your
                    personal information and data is maintained, used only for the provision of their services to us,
                    and handled in accordance with this privacy policy. Examples of service providers include payment
                    processors, hosting services, email service providers, and web traffic analytics tools.
                </li>
                <br />
                    <li>Your account details to your billing contact. If your details (as the account holder) are
                        different to the billing contact listed for your account, we may disclose your identity and
                        account details to the billing contact upon their request (we also will usually attempt to
                        notify you of such requests). By using our services and agreeing to this privacy policy, you
                        consent to this disclosure.
                    </li>
                    <br />
                        <li>Your email address to your organization. If the email address under which you’ve registered
                            your account belongs to or is controlled by an organization, we may disclose that email
                            address to that organization in order to help it understand who associated with that
                            organization uses Onepage, and to assist the organization with its enterprise accounts.
                            (Please do not use a work email address for our services unless you are authorized to do so,
                            and are therefore comfortable with this disclosure.)
                        </li>
                        <br />
                            <li>Aggregated or de-identified information to third parties to improve or promote our
                                services. No individuals can reasonably be identified or linked to any part of the
                                information we share with third parties to improve or promote our services.
                            </li>
                            <br />
                                <li>The presence of a cookie to advertise our services. We may ask advertising networks
                                    and exchanges to display ads promoting our services on other websites. We may ask
                                    them to deliver those ads based on the presence of a cookie, but in doing so will
                                    not share any other personal information with the advertiser. Our advertising
                                    network partners may use cookies and page tags or web beacons to collect certain
                                    non-personal information about your activities on this and other websites to provide
                                    you with targeted advertising based upon your interests.
                                </li>
                                <br />
                                    <li>Your information if required or permitted by law. We may disclose your
                                        information as required or permitted by law, or when we believe that disclosure
                                        is necessary to protect our rights, protect your safety or the safety of others,
                                        and/or to comply with a judicial proceeding, court order, subpoena, or other
                                        legal process served on us.
                                    </li>
                                    <br />
                                        <li>Your information if there is a change in business ownership or structure. If
                                            ownership of all or substantially all of our business changes, or we
                                            undertake a corporate reorganization (including a merger or consolidation)
                                            or any other action or transfer between Onepage entities, you expressly
                                            consent to Onepage transferring your information to the new owner or
                                            successor entity so that we can continue providing our services. If
                                            required, Onepage will notify the applicable data protection agency in each
                                            jurisdiction of such a transfer in accordance with the notification
                                            procedures under applicable data protection laws.
                                        </li>
                                        <br />
                                            <li>Information you expressly consent to be shared. For example, we may
                                                expressly request your permission to provide your contact details to
                                                third parties for various purposes, including allowing those third
                                                parties to contact you for marketing purposes. (You may later revoke
                                                your permission, but if you wish to stop receiving communications from a
                                                third party to which we provided your information with your permission,
                                                you will need to contact that third party directly.)
                                            </li>
                                            <br /></ol>
            <h2> What are your rights to your information?</h2><p>You can:</p>
            <ol type="a" align="justify">
                <li>Update your account details. You can update your registration and other account information on your
                    My Account page. Information is updated immediately.
                </li>
                <br />
                    <li>Access and correct your personal information. As an account holder, you may access and correct
                        certain personal information that Onepage holds about you by visiting your My Account page.
                        These rights are subject to some exceptions, such as where giving you access would have an
                        unreasonable impact on the privacy of other individuals. We will respond to your request to
                        exercise these rights within a reasonable time and, where required by law or where we deem it
                        otherwise appropriate and practicable to do so, we will honor your request.
                    </li>
                    <br />
                        <li>Download/backup your data. Depending on what subscription plan you have, we provide you with
                            the ability to export share and publish your project data in a variety of formats. This
                            allows you to create your own backups or conduct offline data analysis.
                        </li>
                        <br />
                            <li>Delete your data. Deleting project data in the ways described on this page will not
                                permanently delete project data immediately. As long as you maintain an account with us,
                                we may retain your deleted data for a limited time in case you delete something by
                                accident and need to restore it (which you can request by contacting customer support).
                                To the extent permitted by law, we will permanently delete your data if you request to
                                cancel your account. However, if your data was previously made available to the public
                                through a public link, additional copies of your data may remain available on the
                                Internet even after your account has been deleted.
                            </li>
                            <br />
                                <li>Cancel your account. To cancel and delete your account, please contact customer
                                    support. Deleting your account will cause all the data in the account to be
                                    permanently deleted from our systems within a reasonable time period, as permitted
                                    by law, and will disable your access to any other services that require a Onepage
                                    account. We will respond to any such request, and any appropriate request to access,
                                    correct, update or delete your personal information within the time period specified
                                    by law (if applicable) or without excessive delay. We will promptly fulfill requests
                                    to delete personal data unless the request is not technically feasible or such data
                                    is required to be retained by law (in which case we will block access to such data,
                                    if required by law).
                                </li>
                                <br /></ol>
            <h2>Security, cookies and other important information</h2><p>We may modify this privacy policy at any time,
                but if we do so, we will notify you by publishing the changes on this website.</p><p>If we determine the
                changes are material, we will provide you with additional, prominent notice as is appropriate under the
                circumstances, such as via email or in another conspicuous manner reasonably designed to notify you.</p>
            <p>If, after being informed of these changes, you do not cancel your subscription and continue to use our
                services beyond the advance-notice period, you will be considered as having expressly consented to the
                changes in our privacy policy. If you disagree with the terms of this privacy policy or any updated
                privacy policy, you may close your account at any time.</p>
            <ol type="a" align="justify">
                <li>Security. We are committed to handling your personal information and data with integrity and care.
                    However, regardless of the security protections and precautions we undertake, there is always a risk
                    that your personal data may be viewed and used by unauthorized third parties as a result of
                    collecting and transmitting your data through the Internet. If you have any questions about the
                    security of your personal information, contact customer support.
                </li>
                <br />
                    <li>Data locations. Our servers are based in the North America and/or Central America and/or South
                        America, so your personal information will be hosted and processed by us in any of those
                        locations. Your personal information may also be processed in, or transferred or disclosed to,
                        countries in which Onepage subsidiaries and offices are located and in which our service
                        providers are located or have servers. You can view where our offices are located on the Office
                        Locations page.
                    </li>
                    <br />
                        <li>Cookies. Our partners and we, use cookies and similar technologies on our websites. Cookies
                            are small bits of data we store on the device you use to access our services so we can
                            recognize repeat users. Each cookie expires after a certain period, depending on what we use
                            it for. We use cookies and similar technologies for several reasons:
                        </li>
                        <br />
                            <ol align="justify">
                                <li>To make our site easier to use. If you use the “Remember me” feature when you sign
                                    into your account, we may store your username in a cookie to make it quicker for you
                                    to sign in whenever you return to Onepage.
                                </li>
                                <br />
                                    <li>For security reasons. We use cookies to authenticate your identity, such as
                                        confirming whether you are currently logged into Onepage.
                                    </li>
                                    <br />
                                        <li>To provide you with personalized content. We may store user preferences,
                                            such as your default language, in cookies to personalize the content you
                                            see. We also use cookies to ensure that users cannot retake data that they
                                            have already completed.
                                        </li>
                                        <br />
                                            <li>To improve our services. We use cookies to measure your usage of our
                                                websites and track referral data, as well as to occasionally display
                                                different versions of content to you. This information helps us to
                                                develop and improve our services and optimize the content we display to
                                                users.
                                            </li>
                                            <br />
                                                <li>To advertise to you. We, or our service providers and other third
                                                    parties we work with, may place cookies when you visit our website
                                                    and other websites or when you open emails that we send you, in
                                                    order to provide you with more tailored marketing content (about our
                                                    services or other services), and to evaluate whether this content is
                                                    useful or effective. For instance, we may evaluate which ads are
                                                    clicked on most often, and whether those clicks lead users to make
                                                    better use of our tools, features and services.
                                                </li>
                                                <br />
                                                    <li>Google Analytics. In addition to the above, we have implemented
                                                        on our websites and other services certain Google Analytics
                                                        features that support Display Advertising, including
                                                        re-targeting. Visitors to our websites may opt out of certain
                                                        types of Google Analytics tracking, customize the Google Display
                                                        Network ads by using the Google Ad Preferences Manager and learn
                                                        more about how Google serves ads by viewing its Customer Ads
                                                        Help Center. If you do not wish to participate in Google
                                                        Analytics, you may also download the Google Analytics opt-out
                                                        browser add-on.
                                                    </li>
                                                    <br />
                                                        <li>You can still choose to remove or disable cookies via your
                                                            browser. Refer to your web browser’s configuration
                                                            documentation to learn how to do this. Please note that
                                                            doing this may adversely influence your ability to use our
                                                            services. Enabling cookies ensures a smoother experience
                                                            when using our websites. To manage flash cookies, click
                                                            here. By using our websites and agreeing to this privacy
                                                            policy, you expressly consent to the use of cookies as
                                                            described in this policy.
                                                        </li>
                                                        <br />
                                                            <li>Blogs and Forums. Our websites may offer publicly
                                                                accessible blogs and community forums. You should be
                                                                aware that any information you provide in these areas
                                                                may be read, collected, and used by others who access
                                                                them. We are not responsible for any personal
                                                                information you choose to submit in these areas of our
                                                                site. To request removal of your personal information
                                                                from our blog or community forum, contact customer
                                                                support. In some cases, we may not be able to fulfill
                                                                your request and we will let you know why. Commenting
                                                                systems on our blogs are managed by a third party
                                                                application that may require you to register to post a
                                                                comment. Please review that application’s privacy policy
                                                                to learn how the third party uses your information.
                                                            </li>
                                                            <br />
                                                                <li>Online Tracking. We currently do not process or
                                                                    comply with any web browser’s “do not track” signal
                                                                    or other similar mechanism that indicates a request
                                                                    to disable online tracking of individual users who
                                                                    visit our websites or use our services (unless
                                                                    otherwise stated in a service-specific privacy
                                                                    statement).
                                                                </li>
                                                                <br />
                                                                    <li>Safety of Children and COPPA. Our services are
                                                                        not intended for and may not permissibly be used
                                                                        by individuals under the age of 13. Onepage does
                                                                        not knowingly collect personal data from persons
                                                                        under 13 or allow them to register. If it comes
                                                                        to our attention that we have collected personal
                                                                        data from such a person, we may delete this
                                                                        information without notice. If you have reason
                                                                        to believe that this has occurred, please
                                                                        contact customer support.
                                                                    </li>
                                                                    <br />
                                                                        <li>English version controls. Non-English
                                                                            translations of this privacy policy are
                                                                            provided for convenience. In the event of
                                                                            any ambiguity or conflict between
                                                                            translations, the English version is
                                                                            authoritative.
                                                                        </li></ol></ol>
            <h2>Consents</h2><p>By clicking “I Agree” or any other button indicating your acceptance of this privacy
                policy, you expressly consent to the following:</p><p>You consent to the collection, use, disclosure and
                processing of your personal data in the manner described in this privacy policy, including our
                procedures relating to cookies, IP addresses and log files.</p><p>You consent and agree that we may
                transfer your data-to-data processors located in other countries, which do not have data protection laws
                that provide the same level of protection that exists in countries in the European Economic Area. Your
                consent is voluntary, and you may revoke your consent by opting out at any time. Please note that if you
                opt-out, we may no longer be able to provide you our services.</p><p>You consent to us sharing your
                personal data with relevant persons working for service providers who assist us to provide our
                services.</p><p>If you have enabled cookies on your web browser, you consent to our use of cookies as
                described in this privacy policy.</p><br /><br /><br /><br /><p style={{"text-align":"center"}}>Copyright © 2020
                Onepage.Bussiness All Rights Reserved.</p></div>
)
}
