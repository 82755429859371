import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import profile from './modules/cuenta/profile';
import usuarios from './modules/usuarios/usuarios';
import notificaciones from './modules/notificaciones/notificaciones';
import setUpGeneral from './modules/ProjectSetUp/formGeneral';
import formlyAll from './modules/ProjectSetUp/formlyAll';
import geogrephy from './modules/Geography/geography';
import products from './modules/Products/Products';
import cogs from "./modules/Cogs/Cogs";
import sags from "./modules/SG&A/SG&A";
import invest from "./modules/Investments/Investments";
import report from "./modules/Report/report";
import inicio from "./modules/inicio/inicio";
import users from "./modules/users/users";
import subscription from "./modules/subscription/subscription";

export default combineReducers({
    form: formReducer,
    inicio,
    login,
    register,
    profile,
    usuarios,
    routing,
    notificaciones,
    setUpGeneral,
    formlyAll,
    geogrephy,
    products,
    cogs,
    sags,
    invest,
    report,
    users,
    subscription,
});
