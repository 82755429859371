import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from "redux-form";
import moment from "moment";
import FilterForm from "./FilterForm";
import SubscriptionForm from "./SubscriptionForm";
import Grid from "../../../Utils/Grid";
import {RenderNumberGrid} from "../../../Utils/renderField/renderReadField";
import { TableHeaderColumn } from "react-bootstrap-table";

class TabSubPaym extends Component {

    render() {
        const validation = localStorage.getItem('val') === "true";
        const { initialValues, dispatch, selectS, me, hasPlan } = this.props;

        const data = {
            count: selectS.payments.length || 0,
            results: selectS.payments || [],
        };

        const styleLinea = { width: "100%", borderRadius: "25px" };
        const filter = (data) => {
            const end_date = moment(data.end_date).format("YYYY-MM-DD");
            const start_date = moment(data.start_date).format("YYYY-MM-DD");
            this.props.getFilter(`profiles/plan/?end_date=${end_date}&id=${me.id}&start_date=${start_date}`);
        };



        return (
            <div className="content-wizarform">
                <h5 className="font-weight-bold ">SUBSCRIPTION INFORMATION</h5>
                <div className="lineaAzul" style={{ ...styleLinea, height: 5 }} />
                <br />
                <SubscriptionForm
                    plan={this.props.plan}
                    subscript={this.props.subscription}
                    updatePerfil={this.props.updatePerfil}
                    hasPlan={this.props.hasPlan}
                    me={me}
                    form="suscription"
                    onSubmit={() => {}}
                    initialValues={initialValues}
                />
                <br />
                {validation === true && (<h5 className="font-weight-bold ">PAYMENTS</h5>)}
                {validation === true && (<div className="lineaAzul" style={{ height: 5 }} />)}
                {validation === true && (<br />)}
                <FilterForm
                    form="filter"
                    onSubmit={filter}
                />
                <Grid
                    page={1}
                    hover
                    pagination={false}
                    data={data}
                    loading={this.props.loader}
                    // onPageChange={onPageChange}
                    // onSortChange={onSortChange}
                >
                    <TableHeaderColumn isKey tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}
                        dataAlign="left" dataField="date" dataSort>Date Stamp</TableHeaderColumn>
                    <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}
                        dataAlign="left" dataField="amount" dataFormat={RenderNumberGrid} dataSort>Amount</TableHeaderColumn>
                </Grid>
            </div>
        );
    }
}

export default connect()(TabSubPaym);
