import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import { AlertManager } from "alerts";
import { actions as actionsSetUp } from "../ProjectSetUp/formGeneral"

const storeId = "geogrephy";
const resourceList = undefined;

const constants = {
    LOADER: `${storeId.toUpperCase()}_LOADER`,
    DATA: `${storeId.toUpperCase()}_DATA`,
    ITEM: `${storeId.toUpperCase()}_ITEM`,
    PAGE: `${storeId.toUpperCase()}_PAGE`,
    ORDERING: `${storeId.toUpperCase()}_ORDERING`,
    SEARCH: `${storeId.toUpperCase()}_SEARCH`,
};

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: constants.LOADER,
    loader,
});

const setData = data => ({
    type: constants.DATA,
    data,
});

const setItem = item => ({
    type: constants.ITEM,
    item,
});

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

// -----------------------------------
// Actions
// -----------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore()[storeId];
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const setGeographyData = (data) => (dispatch) => {
    const dataGeography = [];
    data.results.forEach((val) => {
        val.segment.forEach((value, i) => {
            const name = val.name + "-" + value.name;
            dataGeography.push({id: value.id, name: name, names: val.name, namet: value.name, value: value.id, label: name});
        });
    });
    dispatch(actionsSetUp.setGeographyList(dataGeography));
};

const leer = (endpoint, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}?id_project=${id}`).then((response) => {
        dispatch(setItem(response));
        dispatch(setGeographyData(response));
        if (!!formName)
            dispatch(initializeForm(formName, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (endpoint, data, onSubmit) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(endpoint, data).then(() => {
        AlertManager.success('Register created', 'Success', 3000);
        if (!!resourceList)
            dispatch(push(resourceList));
        onSubmit && onSubmit();
    }).catch((e) => {
        AlertManager.error('Error on the creation', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (endpoint, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(endpoint, data).then(() => {
        AlertManager.success('Register updated', 'Success', 3000);
        if (!!resourceList)
            dispatch(push(resourceList));
    }).catch(() => {
        AlertManager.error('Error updating', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = (endpoint, endpointLeer, id, idProject) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}`).then(() => {
        //dispatch(listar());
        dispatch(leer(endpointLeer, idProject));
        AlertManager.success('Register deleted', 'Success', 3000);
    }).catch((e) => {
        AlertManager.error(e.detail, 'Error!', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore()[storeId].ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
};

// -----------------------------------
// Reducers
// -----------------------------------

export const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: [],
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState, actions);
