import React, { Component } from 'react';
import "./inicio.css";
import Grid from "../Utils/Grid";
import {RenderNumberGrid} from "../Utils/renderField/renderReadField";

import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../../../../frontend/src/js/common/components/Utils/Grid/StandardActions";
import Modal from "react-responsive-modal";
import FormTabs from "../ProjectContainer/TabsContainer/FormTabs";
import validate from "./validate";
import Link from "react-router-dom/Link";
import LoadMask from "../../../../../../frontend/src/js/common/components/Utils/LoadMask/LoadMask";

class Inicio extends Component {

    state = {
        page: 1,
        subscription: { count: 0, results: [], },
        open: false,
    };

    componentDidMount() {
        this.props.getSubscription(this.state.page);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== nextContext) {
            this.setState({ subscription: nextProps.subscription });
        }
    }

    eliminar = (id) => {
        this.props.deletSubscription(id, {}, () => this.props.getSubscription(this.state.page));
    };

    onOpen = () => this.setState({ open: true});
    onClose = () => this.setState({ open: false});

    onSubmit = (data) => {
        this.props.crear("subscriptions/", data, this.onClose);
    };

    render() {
        const {
            props: { loader },
            state: { page, subscription, open }
        } = this;
        const funcionA = "(Number(val*12)-Number(Number(Number(props.discount)/100)*Number(Number(val)*12)))";
        const funcionD = "(Number(props.amount*12)-Number(Number(Number(val)/100)*Number(Number(props.amount)*12)))";
        const styleModal = { modal: { borderRadius: 25,  maxWidth: "70% !important",  width: "100%", padding: "0px", margin: "50px auto" , padding: "0px" }, };
        const styleLinea = { width: "100%" };
        const input = [
            { name: "type", value: "", label: "Subscription type", type: "input" },
            { name: "descripcion", value: "", label: "Description", type: "input" },
            { name: "amount", value: "", label: "Monthly fee", type: "inputNumber",
                dataHijosAsociados: [
                            // eslint-disable-next-line max-len
                            { name: "annual", evalFale: funcionA, eval: funcionA, condicion: true },
                        ],
            },
            { name: "discount", value: "", label: "Discount", type: "inputNumber",
                dataHijosAsociados: [
                            // eslint-disable-next-line max-len
                            { name: "annual", evalFale: funcionD, eval: funcionD, condicion: true },
                        ],
            },
            { name: "annual", value: "", label: "Annual fee", type: "inputNumber", disable: "true" },
        ];
        const initialValues = {
            type: "",
            descripcion: "",
            amount: 0,
            discount: 0,
            annual: 0,
        };
        return (
            <LoadMask loading={loader} blur positionM="inherit">
                <div className="div-border-round m-4">
                    <div className="form-row align-items-end">
                        <div className={`form-group input-group col-md-12 col-lg-8 text-left`}>
                            <div className={`form-group input-group col-md-12 col-lg-8`}>
                                <button className="btn btn-primary btn-sm mr-1" onClick={this.onOpen} >Add a new Subscription</button>
                                <Link to="featureslist" className="btn btn-optional btn-sm mr-1" >Features list</Link>
                            </div>
                            <div className={`form-group input-group col-md-12 col-lg-4`}>
                            </div>
                        </div>
                        <div className={`form-group input-group col-md-12 col-lg-4 text-right sinbordeSelect`}>
                            <div className="form-row align-items-end" style={{ width: "100%" }}>
                                <div className={`form-group input-group col-md-6`}>

                                </div>
                                <div className={`form-group input-group col-md-6`}>
                                    <div className="inputWithIcon">
                                        <i style={{ cursor: "pointer" }} className="fa fa-search fa-lg" aria-hidden="true" onClick={() => this.getData()} />
                                        <input
                                            type="text"
                                            className="form-control"
                                            style={{ width: "100%" }}
                                            //value={this.state.seach}
                                            onKeyPress={ this.enterKeyPress }
                                            onChange={(val) => {
                                                this.setState({ seach: val.target.value, filtro: 3 })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4>SUBSCRIPTION</h4>
                    <Grid
                        page={page}
                        hover
                        data={subscription}
                        // loading={loader}
                        onPageChange={(e) => {this.props.getSubscription(e); this.setState( { page: Number(e) }) } }
                    >
                        <TableHeaderColumn
                            dataField="id" dataAlign="center" dataSort isKey
                            dataFormat={
                                standardActions( { eliminar: this.eliminar } )
                            }
                        > ACTIONS </TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}
                            dataAlign="left" dataField="type" dataSort>Name</TableHeaderColumn>
                        <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}
                            dataAlign="left" dataField="amount" dataFormat={RenderNumberGrid} dataSort>Cost</TableHeaderColumn>
                    </Grid>

                    <Modal
                        open={open}
                        animationDuration={1000}
                        onClose={this.onClose}
                        center
                        styles={styleModal}
                        closeIconSize={0}
                    >
                        <div className="div-center my-4">
                            <h5 className="font-weight-bold">NEW SUBSCRIPTION</h5>
                            <div className="lineaAzul" style={{ ...styleLinea, height: 20 }} />
                        </div>
                        <div className="mx-5 my-3">
                            <FormTabs
                            form="guestForm"
                            onSubmit={this.onSubmit}
                            arrayInput={input}
                            columInput="2"
                            validate={validate}
                            initialValues={initialValues}
                            enableReinitialize />
                        </div>
                    </Modal>
                </div>
            </LoadMask>
        );
    }
}

export default Inicio;
