import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/cuenta/profile';
import { actions as actionsFrmly } from '../../../../redux/modules/ProjectSetUp/formlyAll';
import Profile from './Profile';


const ms2p = (state) => {
    return {
        ...state.profile,
        formly: state.formlyAll.item,
    };
};

const md2p = { ...actions, formlyAll: actionsFrmly.leer };

export default connect(ms2p, md2p)(Profile);
