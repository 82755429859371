import React, { Component } from 'react';
import "./inicio.css";
import { reset, submit } from "redux-form";
import { connect } from "react-redux";
import Grid from "../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../Utils/Grid/StandardActions";
import {deletUser, getSubscription} from "../../../redux/modules/users/users";
import Modal from "react-responsive-modal";
import FormTabs from "../ProjectContainer/TabsContainer/FormTabs";
import validateGuestUsers from "../LoginRegister/Profile/TabsProfile/validateGuestUser";
import { PERMSSIONS } from "../../../utility/contry";
import RegisterForm from "./RegisterForm";
import LoadMask from "../../../../../../frontend/src/js/common/components/Utils/LoadMask/LoadMask";


class Inicio extends Component {
    state = {
        open: false,
        datUsers: { count: 0, results: [] },
        pageUsers: 1,
    };

    componentDidMount() {
        const { props: { getUsersBySubs, getSubscription } } = this;
        getUsersBySubs(1);
        getSubscription();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== nextContext) {

            if (nextProps.item.length > 0) {
                nextProps.item.forEach((val) => {
                    val.key === "NUMBER_OF_PROJECTS" && this.setState({ numberProject: val.value });
                });
            }
            this.setState({ datUsers: nextProps.users });
        }
    }

    onOpenModal = () => {
        this.setState({ open: true }, () => {
            this.props.dispatch(reset("guestForm"));
        });
    };

    onCloseModal = () => this.setState({ open: false });

    editarModal = (e) => {
        this.props.history.push(`/user-profile/${e}`);
    };

    eliminar = (e) => {
        const { props, state } = this;
        props.deletUser(e, e, state.pageUsers);
    };

    onSubmit = (data) => {
        this.props.onSubmit(data, this.onCloseModal);
    };

    render() {
        const {
            props: { loaderU, getUsersBySubs, getUsersExcel, subscription },
            state: { datUsers, pageUsers, open },
        } = this;
        const styleModal = { modal: { borderRadius: 25, maxWidth: "70% !important", width: "100%", padding: "0px", margin: "50px auto" , padding: "0px" } };
        const styleLinea = { width: "100%" };

        return (
            <div className="m-4">
                <LoadMask loading={loaderU} blur positionM="inherit">
                    <br />
                    <h4>USERS</h4>
                    <div className="div-border-round">
                        {/* eslint-disable-next-line max-len,react/button-has-type */}
                        <button className="btn btn-primary btn-sm mr-1" style={{ width: 125 }} onClick={this.onOpenModal}>New User</button>
                        &nbsp;&nbsp;
                        {/* eslint-disable-next-line max-len,react/button-has-type */}
                        <button className="btn btn-optional btn-sm mr-1" style={{ width: 125 }} onClick={getUsersExcel}>Export to Excel</button>
                        <br />
                        <br />
                        <Grid
                            page={pageUsers}
                            hover
                            data={datUsers}
                            // loading={loaderU}
                            onPageChange={(e) => { getUsersBySubs(e); this.setState({ pageUsers: Number(e) }); }}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                isKey
                                dataFormat={
                                    standardActions({ editarClick: this.editarModal, eliminar: this.eliminar })
                                }
                                style={{ fontSize: "10px" }}
                            >
                                ACTIONS
                            </TableHeaderColumn>
                            <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}
                                dataAlign="left" dataField="first_name" dataSort>First name</TableHeaderColumn>
                            <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}
                                dataAlign="left" dataField="last_name" dataSort>Last name</TableHeaderColumn>
                            <TableHeaderColumn tdStyle={{ whiteSpace: 'normal' }} thStyle={{ whiteSpace: 'normal' }}
                                dataAlign="left" dataField="username" dataSort>Email</TableHeaderColumn>
                        </Grid>
                    </div>
                    <Modal
                        open={open}
                        animationDuration={1000}
                        onClose={this.onCloseModal}
                        center
                        styles={styleModal}
                        closeIconSize={0}
                    >
                        <div className="mx-5 my-4">
                            <h5 className="font-weight-bold">NEW USER</h5>
                        </div>
                        <div className="lineaAzul" style={{ ...styleLinea, height: 20 }} />
                        <div className="mx-5 my-4">
                            <div className="font-weight-bold">PERSONAL INFORMATION</div>
                            <div className="lineaAzul" style={{ height: 3 }} />
                            <RegisterForm suscriptions={subscription} onSubmit={this.onSubmit} loading={loaderU} />
                            {console.log("loaderUloaderUloaderU", loaderU)}
                        </div>
                    </Modal>
                </LoadMask>
            </div>
        );
    }
}

export default connect()(Inicio);
