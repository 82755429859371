import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/subscription/subscription';
import { actions as actionsFrmly } from '../../../redux/modules/ProjectSetUp/formlyAll';
import { actions as actionsSetUp } from '../../../redux/modules/ProjectSetUp/formGeneral';
import { actions as actionsCogs } from '../../../redux/modules/Cogs/Cogs';

import SuscripcionContainer from './FeatureListContainer';


const ms2p = (state) => {
    return {
        ...state.subscription, me: state.login.me, data: state.formlyAll.data, item: state.formlyAll.item,
    };
};

const md2p = {
    ...actions,
    formlyAll: actionsFrmly.leer,
    setEditForm: actionsSetUp.setEditForm,
    setItem: actionsCogs.setItem,
};

export default connect(ms2p, md2p)(SuscripcionContainer);
