export const validateSecurity = (values) => {
    const errors = {};
    if (!values.question1) { errors.question1 = 'Required'; }
    if (!values.question2) { errors.question2 = 'Required'; }
    if (!values.answer1) { errors.answer1 = 'Required'; }
    if (!values.answer2) { errors.answer2 = 'Required'; }

    return errors;
};


export default validateSecurity;
