import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm, reset } from 'redux-form';
import { AlertManager } from "alerts";
import { api } from "api";

const SUBMIT = 'LOGIN_SUBMIT';
const LOADER = 'LOGIN_LOADER';
const ME = 'LOGIN_ME';
const ERROR_LOGIN = 'ERROR_LOGIN';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setMe = me => ({
    type: ME,
    me,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    dispatch({ type: ERROR_LOGIN, errorLogin: ''});
    api.get(`/users/check_admin/?username=${data.username}`).then(() => {
        api.post('/token/', data).then((response) => {
            localStorage.setItem('token', response.token);
            localStorage.setItem('user', response.user);
            dispatch(initializeForm('profile', response.user));
            dispatch(setMe(response.user));
            dispatch(push("/"));
        }).catch(() => {
            dispatch({ type: ERROR_LOGIN, errorLogin: 'Sorry, your password was incorrect. Please double-check your password'});
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }).catch(() => {
        dispatch({ type: ERROR_LOGIN, errorLogin: 'Sorry, your password was incorrect. Please double-check your password'});
    }
    ).finally(() => dispatch(setLoader(false)));
};

export const getMe = () => (dispatch) => {
    api.get('/users/me').then((me) => {
        dispatch(initializeForm('profile', me));
        dispatch(setMe(me));
        api.get(`/users/haveprofile/?username=${me.username}`).then((dataHP) => {
            localStorage.setItem('val', dataHP.val);
        }).catch(() => {}).finally(() => {});
    }).catch(() => {}).finally(() => {});
};

export const logOut = () => (dispatch) => {
    api.post('/user/logout').then(() => {
    }).catch(() => {
    }).finally(() => {});
    localStorage.removeItem('token');
};

export const resetPass = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('auth/users/reset_password/', data).then(() => {
        AlertManager.success('Sent', 'Success', 3000);
        dispatch(reset('register'));
    }).catch((e) => {
        AlertManager.error(e, 'ERROR', 0);
    }).finally(() => dispatch(setLoader(false)));
    localStorage.removeItem('token');
};

export const confirmResetPass = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('auth/users/reset_password_confirm/', data).then(() => {
        AlertManager.success('Sent', 'Success', 3000);
        dispatch(reset('register'));
    }).catch((e) => {
        e.token ? AlertManager.error(e.token[0], 'ERROR', 0) : AlertManager.error("Error", 'ERROR', 0);
    }).finally(() => dispatch(setLoader(false)));
    localStorage.removeItem('token');
};

export const actions = {
    onSubmit,
    logOut,
    resetPass,
    confirmResetPass,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ME]: (state, { me }) => {
        return {
            ...state,
            me,
        };
    },
    [ERROR_LOGIN]: (state, { errorLogin }) => {
        return {
            ...state,
            errorLogin
        }
    }
};

export const initialState = {
    loader: false,
    me: {},
    tipoForm: "",
};

export default handleActions(reducers, initialState);
