import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { connect } from 'react-redux';
import {
    SelectField,
    AsyncSelectField,
} from "Utils/renderField/renderField";
import { renderField } from '../../Utils/renderField';
import { OFFICIAL_COUNTRIES } from '../../../../utility/contry';

const filterOptionss = (inputValue, array) => {
    return array.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()));
};

const loadOptionConutry = (inputValue, callback) => {
    setTimeout(() => {
        callback(filterOptionss(inputValue, OFFICIAL_COUNTRIES));
    }, 100);
};

let RegisterForm = (props) => {
    const { handleSubmit, pristine, reset, submitting, suscriptions, password } = props;

    let validPass = false;
    // eslint-disable-next-line max-len
    if (password && password.match("[a-z]") && password.match("[A-Z]") && password.match("[0-9]") && password.match("[!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?]") && (password.length >= 8)) {
        validPass = true;
    }
    return (
        <form name="loginForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group has-feedback">
                <label htmlFor="first_name">First name</label>
                <Field name="first_name" label="Nombre" component={renderField} type="text" className="form-control" />
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="last_name">Last name</label>
                <Field name="last_name" label="Apellido" component={renderField} type="text" className="form-control" />
            </div>
            <div className="form-group has-feedback">
                <label htmlFor="username">Email address</label>
                <Field name="username" label="Usuario" component={renderField} type="text" className="form-control" />
            </div>

            <div className="form-group has-feedback">
                <label htmlFor="country">Country</label>
                <Field
                    name="country"
                    component={AsyncSelectField}
                    loadOptions={loadOptionConutry}
                    labelKey="name"
                    valueKey="code"
                    isSearchable
                    className="form-control"
                />
            </div>

            <div className="form-group has-feedback">
                <label htmlFor="subscription">Subscription type</label>
                <Field
                    name="subscription"
                    isSearchable
                    component={SelectField}
                    options={suscriptions}
                    className="form-control"
                />
            </div>

            <div className="form-group has-feedback">
                <label htmlFor="password">Password</label>
                <Field
                    name="password"
                    label="Contraseña"
                    component={renderField}
                    type="password"
                    className="form-control"
                />
            </div>

            <ul className="ul-tow">
                <li className={password && password.match("[a-z]") ? 'validado' : ''}>One lowercase character</li>
                <li className={password && password.match("[A-Z]") ? 'validado' : ''}>One uppercase character</li>
                <li className={password && password.match("[0-9]") ? 'validado' : ''}>One number</li>
                <li className={password && password.match("[!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?]") ? 'validado' : ''}>One special character</li>
                <li className={password && password.length >= 8 ? 'validado' : ''}>8 characters minimumr</li>
            </ul>

            <div className="form-group has-feedback">
                <label htmlFor="confirmPassword">Confirm password</label>
                <Field
                    name="confirmPassword"
                    disabled={!validPass}
                    label="Confirmar Contraseña"
                    component={renderField}
                    type="password"
                    className="form-control"
                />
            </div>
            <br />
            <div className="buttons-box">
                <button type="submit" className="btn btn-primary m-1 align-self-center">Register</button>
            </div>
        </form>
    );
};

// eslint-disable-next-line no-unused-vars
export const matchPassword = (pass, confirm) => validatorFromFunction((value) => {
    return pass === confirm;
});

RegisterForm = reduxForm({
    form: 'register', // a unique identifier for this form
    validate: (data) => {
        const errors = {};
        if (!data.username) errors.username = 'This field is required';
        if (data.username && !data.username.match("[!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?]")) { errors.username = 'Email invalid'; }
        if (!data.first_name) errors.first_name = 'This field is required';
        if (!data.last_name) errors.last_name = 'This field is required';
        if (!data.password) errors.password = 'This field is required';
        if (!data.country) errors.country = 'This field is required';
        if (!data.subscription) errors.subscription = 'This field is required';
        if (!data.confirmPassword) errors.confirmPassword = 'This field is required';
        if (data.password
            && data.password.match("[a-z]")
            && data.password.match("[A-Z]")
            && data.password.match("[0-9]")
            && data.password.match("[!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?]")
            && data.password.length >= 8
            // eslint-disable-next-line no-empty
        ) { } else errors.password = 'Invalid Password';
        // eslint-disable-next-line max-len,eqeqeq
        if (data.password && data.password !== data.confirmPassword) errors.confirmPassword = 'Passwords do not match';
        return errors;
    },
})(RegisterForm);

const selector = formValueSelector('register');

RegisterForm = connect((state) => {
    const password = selector(state, "password");

    return {
        password,
    };
})(RegisterForm);

export default RegisterForm;
