import { handleActions } from 'redux-actions';
import { initialize as initializeForm } from 'redux-form';
import { AlertManager } from "alerts";
import { api } from "api";
import {push} from "react-router-redux";
import Swal from "sweetalert2";
import {getUsers, setsubscription} from "../../../../../../frontend/src/js/redux/modules/cuenta/profile";

const LOADER = 'SUBSCRIP_LOADER';
const SUBSCRIPTION = 'SUBSCRIP_SUBSCRIPTION';
const FEATURES = 'FEATURES_SUBSCRIPTION';

export const constants = {
    LOADER,
    SUBSCRIPTION,
    FEATURES,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});
const setSubscription = subscription => ({
    type: SUBSCRIPTION,
    subscription,
});
const setFeatures = features => ({
    type: FEATURES,
    features,
});
// ------------------------------------
// Actions
// ------------------------------------

export const getSubscription = page => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`subscriptions/?page=${page}`).then((dataSub) => {
        dispatch(setSubscription(dataSub));
    }).catch(() => {}).finally(() => dispatch(setLoader(false)));
};

export const getFeatures = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`subscriptions/valsubfeatures/`).then((dataFeat) => {
        dispatch(setFeatures(dataFeat));
    }).catch(() => {}).finally(() => dispatch(setLoader(false)));
};

export const deletSubscription = (id, data, funcion) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`subscriptions/soft_delete/?id=${id}`, data).then(() => {
        AlertManager.success('Register deleted', 'Success', 3000);
        funcion && funcion();
    }).catch(() => {
        AlertManager.error('Error on the transaction', 'Error', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (endpoint, data, funcion) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(endpoint, data).then(() => {
        AlertManager.success('Register created', 'Success', 3000);
        if (funcion) {
            funcion();
            dispatch(getSubscription(1));
        }
    }).catch((e) => {
        AlertManager.error('Error on the creation', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    getSubscription,
    getFeatures,
    deletSubscription,
    crear,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SUBSCRIPTION]: (state, { subscription }) => {
        return {
            ...state,
            subscription,
        };
    },
    [FEATURES]: (state, { features }) => {
        return {
            ...state,
            features,
        };
    },
};

export const initialState = {
    loader: false,
    subscription: { count: 0, results: [] },
    features: [],
};

export default handleActions(reducers, initialState);
