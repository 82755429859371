import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from "redux-form";
import FormTabs from "../../../ProjectContainer/TabsContainer/FormTabs";
import PasswordForm from "./PasswordForm";
import validateSecurity from "./validateSecurity";


class TabSecurity extends Component {
    render() {
        const validation = localStorage.getItem('val') === "true";
        const { initialValues, dispatch, selectS } = this.props;
        const styleLinea = { width: "100%", borderRadius: "25px" };
        const input = [
            // eslint-disable-next-line max-len
            { name: "question1", value: "", label: "Security question 1", type: "select", data: selectS.securityQuestion || [], labelKey: "question", valueKey: "id", labelKeyObject: true },
            // eslint-disable-next-line max-len
            { name: "question2", value: "", label: "Security question 2", type: "select", data: selectS.securityQuestion || [], labelKey: "question", valueKey: "id", labelKeyObject: true },
            { name: "answer1", value: "", label: "Answer 1", type: "input" },
            { name: "answer2", value: "", label: "Answer 2", type: "input" },
        ];


        const updatePass = (data) => {
            const me = this.props.me;
            me.password = data.password;
            me.new_password = data.new_password;
            me.confirm_password = data.confirm_password;
            this.props.updatePerfil(`profiles/passwordupdate/?id=${this.props.me.id}`, me);
        };

        const updateSecurityQ = (data) => {
            const me = this.props.me;
            me.profile = data;
            this.props.updatePerfil(`users/update_questions/?id=${this.props.me.id}`, me);
        };

        return (
            <div className="content-wizarform">
                { validation === true && (
                    <React.Fragment>
                        <h5 className="font-weight-bold ">SECURITY QUESTION</h5>
                        <div className="lineaAzul" style={{ ...styleLinea, height: 5 }} />
                        <br />
                        <FormTabs
                            form="updateSecurityQ"
                            arrayInput={input}
                            onSubmit={updateSecurityQ}
                            columInput="2"
                            validate={validateSecurity}
                            buttonSubmit={false}
                            initialValues={initialValues}
                            enableReinitialize={true}
                        />
                        <div className="div-center">
                            <button type="submit" className="btn btn-optional2" onClick={() => dispatch(submit("updateSecurityQ"))}>
                                Update questions
                            </button>
                        </div>
                    </React.Fragment>
                )}
                <br />
                <h5 className="font-weight-bold ">CHANGE MY PASSWORD</h5>
                <div className="lineaAzul" style={{ height: 5 }} />
                <br />
                <PasswordForm
                    form="updatePass"
                    onSubmit={updatePass}
                    initialValues={initialValues}
                />
            </div>
        );
    }
}

export default connect()(TabSecurity);
