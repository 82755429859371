import React from 'react';
import Select, { Creatable, Async } from 'react-select';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Switch from 'react-switch';
import DatePicker from "react-date-picker";
import _ from "lodash";
import Slider from "react-rangeslider";
import DayPicker from '../DayPicker';
import FileUploader from '../FileUploader/FileUploader';
import imgQuestion from "../../../../../assets/img/question.png";

const styleError = { visibility: "hidden" };

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 0,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected, invalid }) => {
        const color = data.value;
        return {
            ...styles,
            control: styles => ({ ...styles, border: !invalid ? '1px solid #959595': '1px solid #ff0000' }),
            backgroundColor: isDisabled ? null : isSelected ? data.value : isFocused ? color : null,
            color: isDisabled ? 'white' : isSelected ? 'white' : isFocused ? 'black' : data.value,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? data.value : color),
            },
        };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.value) }),
};

export const renderField = ({ input, placeholder, disabled = false, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                {...input}
                placeholder={placeholder}
                disabled={disabled}
                type={type}
                className={classNames('form-control', { 'is-invalid': invalid })}
            />
            {/* eslint-disable-next-line no-nested-ternary */}
            { invalid ? (<div className="invalid-feedback">{ error }</div>)
                : (<div style={styleError}>dataa</div>)
            }
        </div>
    );
};

export const renderFieldLabel = ({ input, placeholder, disabled = false, type, meta: { touched, error }, help }) => {
    const invalid = touched && error;
    return (
        <div className="text-left">
            <input
                style={{ border: "0", background: "none", fontSize: "16px", width: "90%" }}
                {...input}
                placeholder={placeholder}
                disabled={disabled}
                type={type}
                className={classNames({ 'is-invalid': invalid })}
            />
            &nbsp;{help && (<img src={imgQuestion} style={{ width: '15px', height: '15px'}} data-tip={help} />)}
        </div>
    );
};

export const renderTextArea = ({ input, placeholder, rows, meta: { touched, error }, }) => {
    const invalid = touched && error;
    return (
        <div>
            <textarea
                {...input}
                placeholder={placeholder}
                style={{ resize: 'none' }}
                rows={rows || 3}
                className={classNames('form-control', { 'is-invalid': invalid })}
            />
            {invalid ? (<div className="invalid-feedback">{error}</div>) : (<div style={styleError}>dataa</div>)}
        </div>
    );
};

export const renderNumber = ({
    input,
    decimalScale,
    placeholder,
    disable = false,
    meta: { touched, error },
    prefix = "",
    suffix = "",
    numberFormat,
    onChangeEspecial,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                displayType="input"
                disabled={disable}
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                value={Number(input.value)}
                thousandSeparator
                prefix={prefix}
                suffix={suffix}
                onValueChange={(values) => {
                    input.onChange(Number(values.value));
                    if (onChangeEspecial) {
                        onChangeEspecial(Number(values.value));
                    }
                }}
            />
            {/* eslint-disable-next-line no-nested-ternary */}
            { invalid ? (<div className="invalid-feedback">{ error }</div>)
                : (<div style={styleError}>dataa</div>)
            }
        </div>
    );
};

export const renderCurrency = ({ input, meta: { touched, error }, prefix = "Q ", placeholder }) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={2}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid ? (<div className="invalid-feedback">{error}</div>) : (<div style={styleError}>dataa</div>)}
        </div>
    );
};

export const renderSwitch = ({input, meta: { touched, error }, label, disabled}) => {
    const invalid = touched && error;
    return (
        <div className="d-flex align-items-center">
            <Switch
                onColor="#32a16b"
                offColor="#969696"
                height={20}
                width={60}
                handleDiameter={24}
                checkedIcon={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 10,
                            color: "white",
                            margin: "auto",
                            paddingLeft: 5
                        }}
                    >ON</div>}
                uncheckedIcon={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 10,
                            color: "white",
                            margin: "auto",
                            paddingRight: 5
                        }}
                    >OFF</div>
                }
                boxShadow={"0px 0px 0px 12x rgba(185,185,185)"}
                offHandleColor={'#434343'}
                disabled={disabled}
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
            />
            &nbsp;{label}
            {invalid ? (<div className="invalid-feedback">{error}</div>) : (<div style={styleError}>dataa</div>)}
        </div>
    );
};

export const renderFieldCheck = ({ input, label, value, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox">
                <label className="needsclick">
                    <input
                        type="checkbox"
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span className="fa fa-check" />
                    &nbsp;{label}
                </label>
            </div>
            {invalid ? (<div className="invalid-feedback">{error}</div>) : (<div style={styleError}>dataa</div>)}
        </React.Fragment>
    )
};

export const renderFieldRadio = ({ input, label, value, onChangeEspecial, disabled = false, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="radio c-radio c-radio-nofont d-flex">
                <label className="negro font-weight-normal">
                    <input
                        type="radio"
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                        onChange={(value) => {
                            input.onChange(value);
                            onChangeEspecial(input.value);
                        }}
                    />
                    <span />
                    &nbsp;{label}
                </label>
            </div>
            {invalid ? (<div className="invalid-feedback">{error}</div>) : (<div style={styleError}>dataa</div>)}
        </React.Fragment>
    )
};


export const renderSlideRanger = ({ input, label, onChangeEspecial, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="slider orientation-reversed">
                <div className="slider-group">
                    <div className="slider-vertical">
                        <div className="div-center">{label}</div>
                        <Slider
                            min={0}
                            max={100}
                            tooltip={false}
                            orientation="vertical"
                            value={Number(input.value || 0)}
                            onChange={(value) => {
                                input.onChange(Number(value));
                            }}
                            handleLabel={`${Number(input.value || 0) || 0}%`}
                        />
                    </div>
                </div>
            </div>
            {invalid ? (<div className="invalid-feedback">{error}</div>) : (<div style={styleError}>dataa</div>)}
        </React.Fragment>
    );
};


export const SelectFieldColor = (
    {
        input,
        disabled,
        isClearable,
        isMulti,
        isSearchable,
        options,
        placeholder,
        labelKey="label",
        valueKey="value",
        meta: { touched, error },
        formatGroupLabel = data => (
            <div style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        ),
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({...option, label: option[labelKey], value: option[valueKey]});
    });
    let value = input.value;
    if (value !== null && value !== undefined) {
        value = _.find(_options, {value});
    }

    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                menuPlacement="auto"
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e[valueKey] : null); }}
                value={value}
                isDisabled={disabled}
                styles={colourStyles}
            />
            {invalid ? (<div className="invalid-feedback">{error}</div>) : (<div style={styleError}>dataa</div>)}
        </React.Fragment>
    )
};

export const SelectField = ({
    input,
    disabled,
    isClearable,
    isMulti,
    isSearchable,
    options,
    placeholder,
    labelKeyObject = false,
    labelKey = "label",
    valueKey = "value",
    onSelect,
    menuPosition = "absolute",
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({...option, label: option[labelKey], value: option[valueKey]});
    });
    let value = input.value[valueKey] || input.value;
    if (value !== null && value !== undefined) {
        value = _.find(_options, { value });
    }

    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}

                menuPlacement="auto"
                menuPortalTarget={document.body}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    if (labelKeyObject === false) input.onChange(e ? e[valueKey] : null);
                    else input.onChange(e);
                    if (onSelect) onSelect(e);
                }}
                value={value || input.value}
                isDisabled={disabled}
                styles={
                    {
                        control: styles => ({ ...styles, border: !invalid ? '1px solid #959595': '1px solid #ff0000' }),
                        menuPortal: base => ({ ...base, zIndex: 999999999 }),
                        menu: base => ({ ...base, borderRadius: 15 }),
                        menuList: base => ({ ...base, borderRadius: 15, padding: 0 })
                    }
                }
            />
            {invalid ? (<div className="invalid-feedback">{error}</div>) : (<div style={styleError}>dataa</div>)}
        </React.Fragment>
    )
};


export const AsyncSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        loadOptions,
        placeholder,
        meta: { touched, error },
    }
) => {
    const invalid = touched && error;

    return (
        <React.Fragment>
            <Async
                isClearable={isClearable}
                cacheOptions
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                menuPlacement="auto"
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e : null); }}
                value={input.value}
                isDisabled={disabled}
                styles={{control: styles => ({ ...styles, border: !invalid ? '1px solid #959595': '1px solid #ff0000' }),}}
            />
            {invalid ? (<div className="invalid-feedback">{error}</div>) : (<div style={styleError}>dataa</div>)}
        </React.Fragment>
    );
};

export const CreatableSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        options,
        placeholder,
        labelKey="label",
        valueKey="value",
        meta: { touched, error }
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({...option, label: option[labelKey], value: option[valueKey]});
    });

    return (
        <React.Fragment>
            <Creatable
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e : null); }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid ? (<div className="invalid-feedback">{error}</div>) : (<div style={styleError}>dataa</div>)}
        </React.Fragment>
    )
};


/**
 * @param photo: este parametro se usa para tener la imagen previa de una imagen en dado caso el formulario es
 * usado para una actualizacion, se espera que sea la ruta donde se encuentra la imagen
 * @param setFile
 * @param className
 * @param disabled
 * @param input
 * @param touched
 * @param error
 * */
export const renderFilePicker = ({photo, setFile, className, disabled, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <FileUploader
                disabled={disabled}
                img = {!!photo ? photo : null}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) setFile(file);
                    };
                    reader.readAsDataURL(file);
                }} />
            {invalid ? (<div className="invalid-feedback">{error}</div>) : (<div style={styleError}>dataa</div>)}
        </div>
    )
};

export const renderDayPicker = ({className, disabled, maxDate, minDate, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DayPicker
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                onChange={e => input.onChange(e)}
                value={input.value}
            />
            {invalid ? (<div className="invalid-feedback">{error}</div>) : (<div style={styleError}>dataa</div>)}
        </div>
    )
};

export const renderDatePicker = ({className, disabled = false, maxDate, minDate, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DatePicker
                onChange={e => input.onChange(e)}
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                value={input.value}
            />
            {invalid ? (<div className="invalid-feedback">{error}</div>) : (<div style={styleError}>dataa</div>)}
        </div>
    )
};

export const RenderField = {
    renderField,
    renderTextArea,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderFieldCheck,
    renderFieldRadio,
    renderSlideRanger,
    renderDatePicker,
    renderFieldLabel,
};
