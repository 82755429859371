import React, { Component } from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import Select from "react-select";
import classNames from "classnames";
import Grid from "../Utils/Grid";
import { standardActions } from "../../../../../../frontend/src/js/common/components/Utils/Grid/StandardActions";
import "./inicio.css";
import { ITEM_NUMBER, ITEM_USD, ITEM, ITEM2, ITEMS_UNIT } from "../../../utility/contry";
import Switch from "react-switch";
import LoadMask from "../Utils/LoadMask/LoadMask";

class Inicio extends Component {
    state = {
        page: 1,
        subscription: { count: 0, results: [] },
        tableHeader: [],
    };

    componentDidMount() {
        const { props, state } = this;
        props.getSubscription(state.page);
        props.getFeatures();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== nextContext) {
            const { state: { tableHeader } } = this;
            this.setState({ tableHeader: nextProps.subscription.results.map(val => val.type) });
            const subscription = nextProps.features.map((val) => {
                const item = { idfeat: val.idfeat, name: val.name };
                for (let i = 0; i < tableHeader.length; i++) {
                    item[tableHeader[i]] = val.values[i];
                    if (item[tableHeader[i]]) {
                        if ((val.idfeat === 2)
                            || (val.idfeat === 5)
                            || (val.idfeat === 7)
                            || (val.idfeat === 12)
                            || (val.idfeat === 21)
                            || (val.idfeat === 22)
                            || (val.idfeat === 23)) {
                            item[tableHeader[i]].type = "select";
                            item[tableHeader[i]].data = ITEM_NUMBER;
                            item[tableHeader[i]].multi = false;
                            // eslint-disable-next-line max-len,no-unused-expressions
                            item[tableHeader[i]].value.value || (item[tableHeader[i]].value = _.find(ITEM_NUMBER, { value: val.values[i].value }));
                        } else if (val.idfeat === 8) {
                            item[tableHeader[i]].type = "select";
                            item[tableHeader[i]].data = ITEMS_UNIT;
                            item[tableHeader[i]].multi = true;
                            // eslint-disable-next-line no-loop-func
                            try {
                                item[tableHeader[i]].value = val.values[i].value.split(",").map((valP) => {
                                    return _.find(ITEMS_UNIT, { id: Number(valP) });
                                });
                            } catch (e) {
                                item[tableHeader[i]].value = val.values[i].value;
                            }

                        } else if ((val.idfeat === 9) || (val.idfeat === 32)) {
                            item[tableHeader[i]].type = "select";
                            item[tableHeader[i]].data = ITEM_USD;
                            // eslint-disable-next-line max-len,no-unused-expressions
                            item[tableHeader[i]].value.value || (item[tableHeader[i]].value = _.find(ITEM_USD, { value: val.values[i].value }));
                        } else if (val.idfeat === 13) {
                            item[tableHeader[i]].type = "select";
                            item[tableHeader[i]].data = ITEM;
                            // eslint-disable-next-line max-len,no-unused-expressions
                            item[tableHeader[i]].value.value || (item[tableHeader[i]].value = _.find(ITEM, { value: val.values[i].value }));
                        } else if (val.idfeat === 15) {
                            item[tableHeader[i]].type = "select";
                            item[tableHeader[i]].data = ITEM2;
                            // eslint-disable-next-line max-len,no-unused-expressions
                            item[tableHeader[i]].value.value || (item[tableHeader[i]].value = _.find(ITEM2, { value: val.values[i].value }));
                        } else if (item[tableHeader[i]].value === "true" || item[tableHeader[i]].value === "false") {
                            item[tableHeader[i]].type = "switch";
                        } else item[tableHeader[i]].type = "input";
                    }

                }
                return item;
            });
            this.setState({ subscription: { count: subscription.length, results: subscription } });
        }
    }

    editar = (id) => {
        const { state, props } = this;
        const itemData = _.find(state.subscription.results, { idfeat: id });
        const item = { name: itemData.name, idfeat: itemData.idfeat };
        delete itemData.name;
        delete itemData.idfeat;
        item.values = Object.entries(itemData).map(([key, value]) => {
            return { value: value.value, idsubf: value.idsubf, type: value.type };
        });
        console.log("enviarr---", item);
        props.crear("subscription_features/subfeaturesupdate/?item=" + JSON.stringify(item), item);
    };

    onSubmit = (data) => {
        this.props.crear("subscriptions/", data, this.onClose);
    };

    cambiar = (index, campo, value) => {
        const { state: { subscription } } = this;
        subscription.results[index][campo].value = value;
        this.setState({ subscription });
    };

    render() {
        const {
            props: { loader },
            state: { page, subscription, tableHeader },
        } = this;
        return (
            <LoadMask loading={this.props.loader} blur positionM="inherit">
                <div className="div-border-round m-4">
                    <h4>SUBSCRIPTION</h4>
                    <Grid
                        page={1}
                        hover
                        pagination={false}
                        data={subscription}
                        // loading={loader}
                    >
                        <TableHeaderColumn
                            dataField="idfeat"
                            dataAlign="center"
                            dataSort
                            isKey
                            dataFormat={
                                standardActions({ editarClick: this.editar })
                            }
                        >
                            ACTIONS
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="name"
                            dataSort
                        >
                            NAME
                        </TableHeaderColumn>
                        {(tableHeader.map((items, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <TableHeaderColumn
                                        dataAlign="left"
                                        dataField={items}
                                        dataSort
                                        dataFormat={
                                            (i, row, objectRw, indice) => (
                                                <div className="form-group">
                                                    {i && (
                                                        <div>
                                                            {/* eslint-disable-next-line max-len */}
                                                            {(i.type && i.type === "input") && (
                                                                <input
                                                                    type="text"
                                                                    value={i.value}
                                                                    className="form-control"
                                                                    onChange={(value) => {
                                                                        this.cambiar(indice, items, value.target.value);
                                                                    }}
                                                                />
                                                            )}
                                                            {/* eslint-disable-next-line max-len */}
                                                            {(i.type && i.type === "select") && (
                                                                <Select
                                                                    className={classNames('react-select-container')}
                                                                    classNamePrefix={classNames('react-select-container')}
                                                                    menuPlacement="auto"
                                                                    menuPortalTarget={document.body}
                                                                    backspaceRemovesValue={false}
                                                                    options={i.data}
                                                                    isMulti={i.multi}
                                                                    placeholder="Select ..."
                                                                    onChange={(value) => {
                                                                        // eslint-disable-next-line max-len
                                                                        this.cambiar(indice, items, value);
                                                                    }}
                                                                    value={i.value}
                                                                    styles={{
                                                                        control: styles => ({ ...styles, border: '1px solid #959595' }),
                                                                        menuPortal: base => ({ ...base, zIndex: 999999999 }),
                                                                        menu: base => ({ ...base, borderRadius: 15 }),
                                                                        menuList: base => ({ ...base, borderRadius: 15, padding: 0 })
                                                                    }}
                                                                />
                                                            )}
                                                            {(i.type && i.type === "switch") && (
                                                                <Switch
                                                                    onColor="#138086"
                                                                    offColor="#969696"
                                                                    height={20}
                                                                    width={60}
                                                                    handleDiameter={24}
                                                                    checkedIcon={
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                height: "100%",
                                                                                fontSize: 10,
                                                                                color: "white",
                                                                                margin: "auto",
                                                                                paddingLeft: 5
                                                                            }}
                                                                        >ON</div>}
                                                                    uncheckedIcon={
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                height: "100%",
                                                                                fontSize: 10,
                                                                                color: "white",
                                                                                margin: "auto",
                                                                                paddingRight: 5
                                                                            }}
                                                                        >OFF</div>
                                                                    }
                                                                    boxShadow="0px 0px 0px 12x rgba(185,185,185)"
                                                                    offHandleColor="#434343"
                                                                    onChange={(value) => {
                                                                        this.cambiar(indice, items, String(value));
                                                                    }}
                                                                    checked={i.value === "true"}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        }
                                    >
                                        {items}
                                    </TableHeaderColumn>
                                </React.Fragment>
                            );
                        }))}
                    </Grid>
                </div>
            </LoadMask>
        );
    }
}

export default Inicio;
