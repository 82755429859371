export const validate = (values) => {
    const errors = {};
    if (!values.type) { errors.type = 'Required'; }
    if (!values.descripcion) { errors.descripcion = 'Required'; }
    if (!values.amount && values.amount < 0) { errors.amount = 'Required'; }
    if (!values.discount && values.discount < 0) { errors.discount = 'Required'; }
    if (!values.annual && values.annual < 0) { errors.annual = 'Required'; }
    return errors;
};


export default validate;
