import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import { AlertManager } from "alerts";

const storeId = "cogs";
const resourceList = undefined;

const constants = {
    LOADER: `${storeId.toUpperCase()}_LOADER`,
    LOADERMONEDA: `${storeId.toUpperCase()}_LOADERMONEDA`,
    DATA: `${storeId.toUpperCase()}_DATA`,
    ITEM: `${storeId.toUpperCase()}_ITEM`,
    ITEMDATAFORM: `${storeId.toUpperCase()}_ITEMDATAFORM`,
    PAGE: `${storeId.toUpperCase()}_PAGE`,
    ORDERING: `${storeId.toUpperCase()}_ORDERING`,
    SEARCH: `${storeId.toUpperCase()}_SEARCH`,
    TOTALIZADORESSALES: `${storeId.toUpperCase()}_TOTALIZADORESSALES`,
    TIPOCAMBIO: `${storeId.toUpperCase()}_TIPOCAMBIO`,
};

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: constants.LOADER,
    loader,
});

const setLoaderMoneda = loaderMoneda => ({
    type: constants.LOADERMONEDA,
    loaderMoneda,
});


const setData = data => ({
    type: constants.DATA,
    data,
});

const setItem = item => ({
    type: constants.ITEM,
    item,
});

const setItemDataForm = itemDataForm => ({
    type: constants.ITEMDATAFORM,
    itemDataForm,
});

const setPage = page => ({
    type: constants.PAGE,
    page,
});

const setOrdering = ordering => ({
    type: constants.ORDERING,
    ordering,
});

const setSearch = search => ({
    type: constants.SEARCH,
    search,
});

const setTotalizadoresSales = totalizadoresSales => ({
    type: constants.TOTALIZADORESSALES,
    totalizadoresSales,
});

const setTipoCambio = tipoCambio => ({
    type: constants.TIPOCAMBIO,
    tipoCambio,
});
// -----------------------------------
// Actions
// -----------------------------------

const listar = (page = 1, endpoint) => (dispatch, getStore) => {
    const resource = getStore()[storeId];
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get(endpoint, params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = (endpoint, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}?id_project=${id}`).then((response) => {
        dispatch(setItem(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leerDataForm = (endpoint, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}?id_project=${id}&w_cogs=false`).then((response) => {
        dispatch(setItemDataForm(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const crear = (endpoint, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(endpoint, data).then(() => {
        AlertManager.success('Register created', 'Success', 3000);
        if (!!resourceList)
            dispatch(push(resourceList));
    }).catch((e) => {
        AlertManager.error('Error on the creation', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leerTotalizadoresSales = (endpoint, data, main_currency) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(endpoint, data).then((response) => {
            dispatch(setTotalizadoresSales(response));
    }).catch((e) => {
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(leerApiFixerIO());
    });
};


const leerApiFixerIO = (numPeticion= 12) => (dispatch) => {
    const format = (number) => ("0" + number).slice(-2);
    let e = new Date();
    e.setMonth(e.getMonth() - numPeticion);
    const fecha = e.getFullYear() +"-"+ format(e.getMonth()+1) +"-"+ format(e.getDate());
    if (numPeticion === 12) {
        dispatch(setTipoCambio([]));
        dispatch(leerPostApiFixerIO({fecha: fecha}, numPeticion - 1));
    } else if (numPeticion > 0) {
        dispatch(leerPostApiFixerIO({fecha: fecha}, numPeticion - 1))
    }
};


const leerPostApiFixerIO = (data, numPeticion) => (dispatch, getState) => {
    const  main_currency = getState().setUpGeneral.formDataSave.proyinfo.main_currency;
    const endpoint = `proyectos/getcurrency/?base=${main_currency}`;
    numPeticion===11 && dispatch(setLoaderMoneda(true));
    api.post(endpoint, data).then((response) => {
        const array = getState().cogs.tipoCambio;
        const salesCurrency = getState().setUpGeneral.formDataSave.proyinfo.sales_currency;
        array.push({date: response.date, rate: response.rates[salesCurrency]});
        dispatch(setTipoCambio(array));
    }).catch((e) => {
    }).finally(() => {
        numPeticion===0 && dispatch(setLoaderMoneda(false));
        dispatch(leerApiFixerIO(numPeticion));
    });
};



const editar = (endpoint, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(endpoint, data).then(() => {
        AlertManager.success('Register updated', 'Success', 3000);
        if (!!resourceList)
            dispatch(push(resourceList));
    }).catch(() => {
        AlertManager.error('Error updating', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = (endpoint, endpointLeer, id, idProject) => (dispatch) => {
    dispatch(setLoader(true));
    let data = { idcogs: id};
    api.post(endpoint, data).then(() => {
        //dispatch(listar());
        AlertManager.success('Register deleted', 'Success', 3000);
    }).catch((e) => {
        AlertManager.error('Error on the transaction', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
        dispatch(leer(endpointLeer, idProject));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore()[storeId].ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

export const actions = {
    listar,
    leer,
    leerDataForm,
    crear,
    leerTotalizadoresSales,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    setItem,
};

// -----------------------------------
// Reducers
// -----------------------------------

export const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.LOADERMONEDA]: (state, { loaderMoneda }) => {
        return {
            ...state,
            loaderMoneda,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.ITEMDATAFORM]: (state, { itemDataForm }) => {
        return {
            ...state,
            itemDataForm,
        };
    },
    [constants.PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [constants.ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [constants.SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [constants.TOTALIZADORESSALES]: (state, { totalizadoresSales }) => {
        return {
            ...state,
            totalizadoresSales,
        };
    },
    [constants.TIPOCAMBIO]: (state, { tipoCambio }) => {
        return {
            ...state,
            tipoCambio,
        };
    },
};

export const initialState = {
    loader: false,
    loaderMoneda: false,
    data: {
        results: [],
        count: 0,
    },
    item: [],
    itemDataForm: [],
    page: 1,
    ordering: '',
    search: '',
    totalizadoresSales: {},
    tipoCambio: [],
};

export default handleActions(reducers, initialState, actions);
