import { handleActions } from 'redux-actions';
import { initialize as initializeForm } from 'redux-form';
import { AlertManager } from "alerts";
import { api } from "api";
import {push} from "react-router-redux";
import Swal from "sweetalert2";
import {setsubscription} from "../../../../../../frontend/src/js/redux/modules/cuenta/profile";
import {setLoader, setSuscripcion} from "../cuenta/register";


const LOADERU = 'INICIO_LOADERU';
const USERS = 'INICIO_USERS';
const SUBSCRIPTION = 'INICIO_SUBSCRIPTION';

export const constants = {
    LOADERU,
    USERS,
    SUBSCRIPTION,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setUsers = users => ({
    type: USERS,
    users,
});
const setLoaderU = loaderU => ({
    type: LOADERU,
    loaderU,
});

const setSubscription = subscription => ({
    type: SUBSCRIPTION,
    subscription,
});

// ------------------------------------
// Actions
// ------------------------------------

export const getUsersBySubs = page => (dispatch) => {
    dispatch(setLoaderU(true));
    api.get(`users/user_list/?page=${page}`).then((data) => {
        dispatch(setUsers(data));
    }).catch(() => {}).finally(() => dispatch(setLoaderU(false)));
};

export const getSubscription = () => (dispatch) => {
    api.get('subscriptions/all/').then((dataSub) => {
        const array = dataSub.map((item) => {
            return { value: item.id, label: `${item.type  } - ${  item.annual}`};
        });
        dispatch(setSubscription(array));
    }).catch(() => {}).finally(() => {});
};

export const getUsersExcel = () => (dispatch) => {
    dispatch(setLoaderU(true));
    api.post("users/export_excel/", {}).then((data) => {
        window.open(data.data, '_blank');
    }).catch(() => {}).finally(() => dispatch(setLoaderU(false)));
};

export const deletUser = (id, data, page) => (dispatch) => {
    dispatch(setLoaderU(true));
    api.put(`users/soft_delete/?id=${id}`, data).then(() => {
        dispatch(getUsersBySubs(1));
        AlertManager.success('Register deleted', 'Success', 3000);
    }).catch(() => {
        AlertManager.success('Error on the transaction', 'Error', 3000);
    }).finally(() => {
        dispatch(setLoaderU(false));
    });
};

export const onSubmit = (data = {}, func) => (dispatch) => {
    console.log("peticionnnnn");
    dispatch(setLoaderU(true));
    // eslint-disable-next-line no-param-reassign
    data.email = data.username;
    api.post('users/profile/', data).then(() => {
        dispatch(getUsersBySubs(1));
        func && func();
        AlertManager.success('Account created successfully, you can login', 'Success', 3000);
    }).catch((e) => {
        if (e.username) {
            AlertManager.error(e.username, 'Error', 3000);
        } else {
            AlertManager.error("An error has occurred, please verify the data", 'Error', 3000);
        }
    }).finally(() => {
        dispatch(setLoaderU(false));
        console.log("finnnnnnnnn");
    });
};

export const actions = {
    getUsersBySubs,
    getUsersExcel,
    deletUser,
    getSubscription,
    onSubmit,
};

export const reducers = {
    [LOADERU]: (state, { loaderU }) => {
        return {
            ...state,
            loaderU,
        };
    },
    [USERS]: (state, { users }) => {
        return {
            ...state,
            users,
        };
    },
    [SUBSCRIPTION]: (state, { subscription }) => {
        return {
            ...state,
            subscription,
        };
    },
};

export const initialState = {
    loaderU: false,
    users: { count: 0, results: [] },
    subscription: [],
};

export default handleActions(reducers, initialState);
