import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import './login.css';
import LoadMask from "Utils/LoadMask/LoadMask";
import logo from "../../../../../assets/img/logo.png";
import arrow_rigth from "../../../../../assets/img/arrow-rigth.png";
import ResetForm from "./ResetForm";

class ResetPassword extends Component {
    render() {
        const { loader, resetPass } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }

        const onSubmit = (data) => {
            resetPass(data);
        };

        return (
            <div className="blue-gradient-bg-register div-center">
                <div className="card card-login-register">
                    <Link className="card-regresar" to="/login">
                        <img src={arrow_rigth} alt="logo" width={10} />
                        Back
                    </Link>
                    <LoadMask loading={loader} light>
                        <img src={logo} alt="logo" width={225} />
                        <ResetForm name="restPass" onSubmit={onSubmit} />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default ResetPassword;
