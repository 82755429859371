import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import './acciones.css';
import Swal from 'sweetalert2';

import imgEditar from "../../../../../assets/img/editar.png";
import imgCancelar from "../../../../../assets/img/cancelar.png";
import  './actions-css.css';

class Acciones extends Component {
    constructor(props) {
        super(props);
    }

    eliminar = (id) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        }
    };

    editarClick = (id) => {
        this.props.editarClick(id);
    };

    render() {
        const { id, ver, editar, editarClick, eliminar } = this.props;

        return (
            <div className="d-flex justify-content-center actions-buttons">
                {(ver !== undefined) && (
                    <Link to={`${ver}/${id}/`} className="px-2" ><i className="material-icons">remove_red_eye</i></Link>
                )}
                {(editar !== undefined) && (
                    <Link className="text-warning" to={`${editar}/${id}/editar`} ><i className="material-icons">edit</i></Link>
                )}
                {(editarClick !== undefined) && (
                    <a  className="text-warning button-container-edit"
                        onClick={() => this.editarClick(id)} 
                    >
                            <img src={imgEditar}
                            />
                    </a>
                )}
                {(eliminar !== undefined) && (
                    <a className="text-warning mx-2 button-container-delete" 
                        onClick={this.eliminar(id)}
                        
                    >
                        <img src={imgCancelar}
                                />
                    </a>
                )}
            </div>
        );
    }
}
Acciones.propTypes = {
};

export function standardActions(acciones) {
    return (cell, row) => {
        return ( <Acciones id={cell} {...acciones}/> )
    };
}
