import React, { Component } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import { connect } from 'react-redux';
import TabProfile from "./TabProfile";
import TabGuest from "./TabGuest";
import TabSecurity from "./TabSecurity";
import TabSubPaym from "./TabSub&Paym";

class WizardTabsProfile extends Component {
    render() {
        const {
            page,
            setPage,
            arrayTabs,
            initialValues,
            selectS,
            users,
            me,
            crear,
            getData,
            getUsers,
            eliminar,
            getFilter,
            nUsers,
        } = this.props;
        return (
            <div className="div-border-round m-4">
                <Tabs
                    defaultActiveKey={page.toString()}
                    activeKey={page.toString()}
                    tabBarPoition="top"
                    onchange={this.callback}
                    renderTabBar={
                        () => (<ScrollableInkTabBar onTabClick={setPage} />)
                    }
                    renderTabContent={() => <TabContent />}
                >
                    {arrayTabs.map((value, index) => (<TabPane tab={value.nombre} key={index.toString()} />))}
                </Tabs>
                {arrayTabs[page] && (
                    <React.Fragment>
                        {arrayTabs[page].type === "profile" && (
                            <TabProfile {...this.props} initialValues={initialValues} selectS={selectS} />)
                        }
                        {arrayTabs[page].type === "guest" && (
                            // eslint-disable-next-line max-len
                            <TabGuest {...this.props} users={users} selectS={selectS} me={me} crear={crear} getUsers={getUsers} eliminar={eliminar} nUsers={nUsers} />)
                        }
                        {arrayTabs[page].type === "security" && (
                            // eslint-disable-next-line max-len
                            <TabSecurity {...this.props} initialValues={initialValues} selectS={selectS} />)
                        }
                        {arrayTabs[page].type === "subscription" && (
                            // eslint-disable-next-line max-len
                            <TabSubPaym {...this.props} initialValues={initialValues} selectS={selectS} me={me} getFilter={getFilter} />)
                        }
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default connect()(WizardTabsProfile);
